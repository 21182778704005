<template>
  <v-card class="search">
    <div xs12 class="search-div">
      <v-text-field
        v-model="search"
        class="ma-4"
        full-width
        outlined
        dense
        ref="search"
        placeholder="Search Product"
        append-icon="mdi-magnify"
        prepend-inner-icon="mdi-arrow-left"
        @input="handleInput()"
        :hide-details="true"
        @click:prepend-inner="$emit('close')"
        @click:append="toSearchPage()"
        @keydown.enter="toSearchPage()"
      ></v-text-field>
    </div>
    <div xs12 class="search-div result pb-5 px-2">
      <div class="mx-4">
        <div v-for="(item, index) in shop" :key="index">
          <div class="mb-1 text-result" @click="toSearchPage(item)">
            <v-icon color="#808080">mdi-magnify</v-icon>
            <span>{{ item.product_name }}</span>
          </div>
        </div>

        <div
          v-if="done && shop.length === 0"
          class="search-div text-result"
          @click="toSearchPage()"
        >
          <v-icon color="#808080">mdi-magnify</v-icon>
          <b> Search for {{ search }} </b>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from "@/axios";

export default {
  name: "SearchComponent",
  data() {
    return {
      search: "",
      done: false,
      shop: [],
    };
  },
  methods: {
    focusInput() {
      setTimeout(() => {
        this.$refs.search.focus();
      }, 100);
    },
    handleImageError(event) {
      event.target.src = "https://spaces.ilfen.co/assets/image/default.png";
    },
    goTo(item) {
      this.$emit("close");
      this.toProduct(item);
    },
    debounce(func, delay) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        func();
      }, delay);
    },
    handleInput() {
      this.debounce(() => {
        if (this.search && this.search.length >= 3) {
          this.searchData();
        } else {
          this.shop = [];
          this.done = false;
        }
      }, 1000);
    },
    toSearchPage(item = null) {
      this.$router.push({
        name: "Search",
        query: { keywords: item ? item.product_name : this.search },
      });
    },
    setInitValue() {
      if (this.$route.name === "Search" && this.$route.query.keywords) {
        this.search = this.$route.query.keywords;
      }
    },
    searchData() {
      if (this.search && this.search.length >= 3) {
        this.$store.commit("setIsLoading", true);

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          key: this.search,
          limit: 5,
          current_page: 1,
        };
        axios
          .get("/api/v1/pages/search")
          .then((response) => {
            this.shop = response.data.data;
          })
          .catch((error) => {
            this.shop = [];
            this.message = error.response.data.api_msg;
          })
          .finally(() => {
            this.done = true;
            this.$store.commit("setIsLoading", false);
          });
      } else {
        this.shop = [];
        this.done = false;
      }
    },
  },
  mounted() {
    this.focusInput();
    this.setInitValue();
  },
};
</script>

<style scoped>
.search {
  width: 100%;
}
.search-div {
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.result {
  max-height: 350px;
  overflow-y: auto;
}
.shop-item {
  position: relative;
  text-align: center;
}
.shop-img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.shop-img:hover {
  opacity: 0.5;
}
.shop-img-m {
  max-width: 100%;
  height: auto;
}
.text {
  font-size: 0.8rem;
  margin: 0;
}
.text-result {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 0.8rem;
  color: #808080;
  /* font-weight: bold; */
  cursor: pointer;
}
.text-through {
  font-size: 0.8rem;
  color: #808080;
  text-decoration: line-through;
  margin: 0;
}
</style>
