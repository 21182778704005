<template>
  <div>
    <v-app-bar app height="65" :color="isHome ? (isTransparentHeader ? 'transparent' : 'white' ): 'white'" elevation="0" class="elevation-0 relative">
      <!-- <v-badge
        v-if="!(isMemberPage && isMobile)" 
        v-model="badgeMenu"
        content="new"
        color="red"
        offset-x="25"
        offset-y="25"
      > -->
        <v-app-bar-nav-icon 
          v-if="!(isMemberPage && isMobile)"
          style="margin-left:-15px;"
          :color="isTransparentHeader? headerColor : '#000'"
          @click="showHideMenu()">
        </v-app-bar-nav-icon>
      <!-- </v-badge> -->

      <v-btn v-if="isMobile && isMemberPage" icon :color="isTransparentHeader? headerColor : '#000'" @click="back()">
        <v-icon medium>mdi-arrow-left</v-icon>
      </v-btn>

      <img v-if="isHome && !isMobile && logoWhite" :src="logoWhite" class="img-logo" :style="brand === '17' ? {marginTop: '10px'} : ''" :alt="logoWhite" @click="toPage('Home')">
      <img v-if="!isHome && !isMobile && logo" :src="logo" class="img-logo" :style="brand === '17' ? {marginTop: '10px'} : ''" :alt="logo" @click="toPage('Home')">

      <v-btn v-if="isMobile && !isLoginPage" icon :color="isTransparentHeader? headerColor : '#000'" @click="$emit('search')" width="25px" height="25px">
        <v-icon medium>mdi-magnify</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
  
      <span v-if="!isMobile" :class="isHome ? 'header-text-w' : 'header-text-b'">
        <span v-if="!isLogin" @click="toPage('Login')" :style="isTransparentHeader ? { color: headerColor } : { color: '#000' }"> 
          {{ $t('login') }} 
        </span>
        <span class="uppercase" v-if="isLogin" @click="toPage('My-Account')" :style="isTransparentHeader ? { color: headerColor } : { color: '#000' }">
          {{ userName}}
        </span>
      </span>

      <span v-if="!isMobile" :class="isHome ? 'header-text-w' : 'header-text-b'" @click="toPage('Help')" :style="isTransparentHeader ? { color: headerColor } : { color: '#000' }">
         {{ $t('help') }}
      </span>

      <!-- <NotificationIcon v-if="!isMobile" :is-transparent-header="isTransparentHeader" /> -->

      <!-- <v-badge
        v-else
        color="black"
        :content="notifNumber"
        :value="notifNumber"
        offset-x="18"
        offset-y="23"
      >
        <v-btn icon :color="isTransparentHeader? headerColor : '#000'" @click="toPage('Notification')" width="40px" height="40px">
          <v-icon s>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge> -->
      
      <v-badge
        color="black"
        :content="cartNumber"
        :value="cartNumber"
        offset-x="18"
        offset-y="23"
      >
        <v-btn icon :color="isTransparentHeader? headerColor : '#000'" @click="toPage('Cart')"  width="40px" height="40px">
          <v-icon medium>mdi-shopping-outline</v-icon>
        </v-btn>
      </v-badge>

      <div v-if="!isHome && route !== 'Product'" class="divider pl-3 pr-3">
        <v-divider color="#c0c0c0" width="100%"></v-divider>
      </div>
    </v-app-bar>

    <div v-if="isHome && !isTransparentHeader && isMobile" class="img-home" :style="{ 'top':  getTopStyle}">
      <img :src="logo" class="img-home-logo" :style="{ 'max-width': maxWidth }" :alt="logo">
    </div>

    <div v-if="!isMobile && !isLoginPage" class="text-center" @click="$emit('search')">
      <span :class="isHome ? 'search-text-w inline'  : 'search-text inline'" :style="searchTextClass"> {{ $t('search') }}</span>
      <div class="inline" :style="isTransparentHeader ? { width: '150px', border: '1px solid', 'border-color' : headerColor } : { width: '150px', border: '1px solid', 'border-color' : '#000' }  "></div>
    </div>

    <div v-if="isMobile && !isHome" class="page-title">
      <span> {{ activePage}} </span>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  // import NotificationIcon from '@/components/Notification/NotificationIcon'

  export default {
    name: 'Header',
    // components: { NotificationIcon },
    props: {
      isTransparentHeader: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState([
        'isSearch',
        'isHome',
        'isMobile',
        'isLoginPage',
        'isMemberPage',
        'activePage',
        'isLogin',
        'userName',
        'cartNumber',
        'headerColor',
        'notifNumber'
      ]),
      searchTextClass () {
        return {
          color: this.isHome ? (this.isTransparentHeader ? this.headerColor : '#000'):  this.headerColor
        }
      },
      route () {
        return this.$route.name
      },
      maxWidth () {
        if (this.brand == '13') {
          return '65px'
        } else if (this.brand === '17') {
          return '75px'
        } else {
          return '100px'
        }
      },
      getTopStyle () {
        if (this.brand == '14') {
          return '0'
        } else if (this.brand === '9') {
          return '20px'
        } else if (this.brand === '5') {
          return '5px'
        } else if (this.brand === '13') { 
          return '0'
        } else if (this.brand === '17') { 
          return '0'
        } else if (this.brand === '15') { 
          return '20px'
        } else if (this.brand === '18') { 
          return '15px'
        } else {
          return '0'
        }
      }
    },
    data () {
      return {
        logo: '',
        badgeMenu: false,
        logoWhite: '',
        brand: process.env.VUE_APP_BRAND
      }
    },
    methods: {
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name })
        }
      },
      showHideMenu () {
        this.badgeMenu = false
        this.$emit('showHide')
      },
      setLogo () {
        this.logo = require('@/assets/logo/'+ this.brand +'/logo.png')
        this.logoWhite = require('@/assets/logo/'+ this.brand +'/logo.png')
      }
    },
    mounted () {
      this.setLogo();

      this.badgeMenu = (this.brand === '9' || this.brand === '5') || false
    }
  }
</script>

<style scoped>
  .header-text-w  {
    color: #FFFFFF;
    margin-left: 20px;
    font-size: 12px !important;
    cursor: pointer;
  }

  .header-text-b  {
    color: #000000;
    margin-left: 20px;
    font-size: 12px !important;
    cursor: pointer;
  }

  .search-text-w {
    /* color: #FFFFFF; */
    margin-right: 10px;
    font-size: 22px !important;
    font-weight: 800;
  }

  .search-text {
    /* color: #000000; */
    margin-right: 10px;
    font-size: 22px !important;
    font-weight: 800;
  }

  .page-title {
    text-transform: uppercase;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 20px;
    margin-bottom: -125px;
    z-index: 20;
    color: #000000;
    font-size: 0.8rem !important;
    font-weight: 700;
  }

  .mr-100 {
    margin-right: 300px;
  }

  .line-w {
    width: 150px;
    border: 1px solid #FFFFFF;
  }

  .line-b {
    width: 150px;
    border: 1px solid #000000;
  }

  .text-center {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 25px;
    margin-bottom: -125px;
    z-index: 20;
  }

  .inline {
    display: inline-block;
  }

  .img-logo {
    width: 100%;
    max-width: 100px;
    cursor: pointer;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .divider {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .img-home {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 20;
  }

  .img-home-logo {
    width: 100%;
    /* max-width: 100px; */
  }
</style>
