<template>
  <div
    :class="headerClass"
    :style="
      isHome
        ? isTransparentHeader
          ? { background: 'transparent' }
          : { background: '#fff' }
        : { background: '#fff' }
    "
  >
    <div
      v-if="isShowNotif && route !== 'Checkout' && route !== 'Cart'"
      class="h-notif"
    >
      <span class="h-notif-text"
        >New feature release, check menu and grab it fast!</span
      >
      <v-icon class="h-notif-close ml-2" small color="#fff" @click="$emit('hideNotif')"
        >mdi-close</v-icon
      >
    </div>
    <div v-if="isShowTab" class="h-top">
      <v-tabs
        v-model="tab"
        active-class="selected-tab"
        color="#fff"
        background-color="transparent"
        :grow="isMobile ? true : false"
        height="40px"
      >
        <v-tab
          v-for="item in brandList"
          :key="item.id"
          @click.stop="toPages(item)"
        >
          <!-- <span class="tab-title">{{ item.name }}</span> -->
          <img :src="tab === item.tab ? item.imgActive : item.img" :alt="item.id" :width="item.param? '100px' : '80px'" />
        </v-tab>
      </v-tabs>
    </div>
    <v-row
      :class="isShowTab ? 'mt-2 px-2' : 'mt-1 px-2'"
      style="position: relative; height:60px; max-height:60px"
    >
      <v-col cols="2" class="py-0">
        <div style="display: flex; justify-content: space-between;">
          <div class="mt-3">
            <v-btn
              v-if="!(isMemberPage && isMobile)"
              icon
              :color="isTransparentHeader ? headerColor : '#000'"
              @click="showHideMenu()"
            >
              <v-icon medium>mdi-menu</v-icon>
            </v-btn>
            <v-btn
              v-if="isMobile && isMemberPage"
              icon
              :color="isTransparentHeader ? headerColor : '#000'"
              @click="back()"
            >
              <v-icon medium>mdi-arrow-left</v-icon>
            </v-btn>
          </div>

          <div style="width: 80%">
            <img
              v-if="isHome && !isMobile && logoWhite"
              :src="logoWhite"
              class="img-logo"
              :style="styleImgMobile"
              @click="toPage('Home')"
            />
            <img
              v-if="!isHome && !isMobile && logo"
              :src="logo"
              class="img-logo"
              :style="styleImgMobile"
              @click="toPage('Home')"
            />
          </div>
           <v-btn
              class="mt-3"
              v-if="isMobile && !isLoginPage"
              icon
              :color="isTransparentHeader ? headerColor : '#000'"
              @click="$emit('search')"
            >
              <v-icon medium>mdi-magnify</v-icon>
            </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="7">
        <div
          v-if="!isMobile && !isLoginPage"
          class="text-center"
          @click="$emit('search')"
        >
          <span
            :class="isHome ? 'search-text-w inline' : 'search-text inline'"
            :style="searchTextClass"
          >
            {{ $t("search") }}</span
          >
          <div
            class="inline"
            :style="
              isTransparentHeader
                ? {
                    width: '150px',
                    border: '1px solid',
                    'border-color': headerColor,
                  }
                : {
                    width: '150px',
                    border: '1px solid',
                    'border-color': '#000',
                  }
            "
          ></div>
        </div>
        <div v-if="isHome && !isTransparentHeader && isMobile" class="img-home">
          <img :src="logo" class="img-home-logo" :style="styleImgMobileCenter" />
        </div>

        <div v-if="isMobile && !isHome" class="page-title">
          <span> {{ activePage }} </span>
        </div>
      </v-col>
      <v-col cols="4" sm="3" align="right">
        <!-- <v-btn
          v-if="isMobile && !isSearch && !isLoginPage"
          icon
          :color="isTransparentHeader ? headerColor : '#000'"
          @click="$emit('search')"
        >
          <v-icon medium>mdi-magnify</v-icon>
        </v-btn> -->

        <span
          v-if="!isMobile"
          :class="isHome ? 'header-text-w' : 'header-text-b'"
        >
          <span
            v-if="!isLogin"
            @click="toPage('Login')"
            :style="
              isTransparentHeader ? { color: headerColor } : { color: '#000' }
            "
          >
            {{ $t("login") }}
          </span>
          <span
            class="uppercase"
            v-if="isLogin"
            @click="toPage('My-Account')"
            :style="
              isTransparentHeader ? { color: headerColor } : { color: '#000' }
            "
          >
            {{ userName }}
          </span>
        </span>

        <span
          v-if="!isMobile"
          :class="isHome ? 'header-text-w' : 'header-text-b'"
          @click="toPage('Help')"
          :style="
            isTransparentHeader ? { color: headerColor } : { color: '#000' }
          "
        >
          {{ $t("help") }}
        </span>

        <!-- <NotificationIcon v-if="!isMobile" :is-transparent-header="isTransparentHeader" /> -->

        <!-- <v-badge
          v-else
          color="black"
          :content="notifNumber"
          :value="notifNumber"
          offset-x="18"
          offset-y="23"
        >
          <v-btn icon :color="isTransparentHeader? headerColor : '#000'" @click="toPage('Notification')" width="40px" height="40px">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </v-badge> -->

        <v-badge
          color="black"
          :content="cartNumber ? cartNumber : '0'"
          offset-x="23"
          offset-y="23"
        >
          <v-btn
            icon
            :color="isTransparentHeader ? headerColor : '#000'"
            @click="toPage('Cart')"
          >
            <v-icon medium>mdi-shopping-outline</v-icon>
          </v-btn>
        </v-badge>

        <div v-if="!isHome && route !== 'Product'" class="divider pl-3 pr-3">
          <v-divider color="#c0c0c0" width="100%"></v-divider>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import NotificationIcon from '@/components/Notification/NotificationIcon'

export default {
  name: "NewHeader",
  // components: {NotificationIcon},
  props: {
    isTransparentHeader: {
      type: Boolean,
      default: false,
    },
    isShowTab: {
      type: Boolean,
      default: true,
    },
    isShowNotif: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      "isSearch",
      "isHome",
      "isMobile",
      "isLoginPage",
      "isMemberPage",
      "activePage",
      "isLogin",
      "userName",
      "cartNumber",
      "headerColor",
      "activeTab",
      "notifNumber"
    ]),
    searchTextClass() {
      return {
        color: this.isHome
          ? this.isTransparentHeader
            ? this.headerColor
            : "#000"
          : this.headerColor,
      };
    },
    headerClass() {
      if (this.isShowTab && this.brand === "14") {
        if (this.isShowNotif) {
          return "header st-height-with-n";
        } else {
          return "header st-height";
        }
      } else {
        if (this.isShowNotif) {
          return "header nst-height-with-n";
        } else {
          return "header nst-height";
        }
      }
    },
    styleImgMobile() {
      if (this.brand === "14") {
        if (this.activeTab && this.activeTab.param) {
          return { marginTop: "5px" };
        } else {
          return { marginTop: 0, maxWidth: "100px"};
        }
      } else if (this.brand === "9") {
        return { marginTop: "18px" };
      } else if (this.brand === "5") {
        return { marginTop: 0 };
      } else if (this.brand === "13") {
        return { marginTop: "-3px", maxWidth: "65px"};
      } else if (this.brand === "18") {
        return { marginTop: "15px", marginLeft: "-12px" };
      } else {
        return { marginTop: 0 };
      }
    },
    styleImgMobileCenter() {
      if (this.brand === "14") {
          if (this.activeTab && this.activeTab.param) {
          return { marginTop: "10px" };
        } else {
          return { marginTop: 0, maxWidth: "100px"};
        }
      } else if (this.brand === "9") {
        return { marginTop: "18px" };
      } else if (this.brand === "5") {
        return { marginTop: 0 };
      } else if (this.brand === "13") {
        return { marginTop: "-4px", maxWidth: "65px"};
      } else if (this.brand === "18") {
        return { marginTop: "15px" };
      } else {
        return { marginTop: 0 };
      }
    },
    route() {
      return this.$route.name;
    },
  },
  watch: {
    route(val) {
      if (val === "Pages") {
        this.tab = 1;
      } else if (val === "Home") {
        this.tab = 0;
      } else {
        if (this.activeTab && this.activeTab.tab) {
          this.tab = this.activeTab.tab;
        } else {
          this.tab = 0;
        }
      }
      this.setSelectedTab();
    },
    activeTab() {
      this.setLogo()
    }
  },
  data() {
    return {
      tab: 0,
      logo: "",
      logoWhite: "",
      brand: process.env.VUE_APP_BRAND,
      brandList: [
        {
          id: 14,
          name: "Prepp Studio",
          route: "Home",
          tab: 0,
          img: require("@/assets/logo/14/logo.png"),
          imgActive: require("@/assets/logo/14/logo.png")
        },
        {
          id: 15,
          name: "Prepp Studio Jr",
          route: "Pages",
          param: "prepps-jr",
          tab: 1,
          img: require("@/assets/logo/14/prepps-jr-black.png"),
          imgActive: require("@/assets/logo/14/prepps-jr.png")
        },
      ],
    };
  },
  methods: {
    toPage(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name: name });
      }
    },
    toPages(item) {
      if (this.$route.name !== item.route) {
        if (item.param) {
          this.$router.push({ name: item.route, params: { id: item.param } });
        } else {
          this.$router.push({ name: item.route });
        }
      }
    },
    setSelectedTab() {
      let selected = this.brandList.find((item) => item.tab === this.tab);
      this.$store.commit("setActiveTab", selected);
    },
    showHideMenu() {
      this.$emit("showHide");
    },
    setLogo() {
      if (this.brand === "14") {
        if (this.activeTab && this.activeTab.param) {
          this.logo = require("@/assets/logo/" + this.brand + "/prepps-jr-black.png");
          this.logoWhite = require("@/assets/logo/" + this.brand + "/prepps-jr-black.png");
        } else {
          this.logo = require("@/assets/logo/" + this.brand + "/logo.png");
          this.logoWhite = require("@/assets/logo/" + this.brand + "/logo.png");
        }
      } else {
        this.logo = require("@/assets/logo/" + this.brand + "/logo.png");
        this.logoWhite = require("@/assets/logo/" + this.brand + "/logo.png");
      }
    },
  },
  mounted() {
    this.setLogo();
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  /* height: 110px; */
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.st-height {
  height: 125px;
}

.nst-height {
  height: 65px;
}

.st-height-with-n {
  height: 160px;
}

.nst-height-with-n {
  height: 110px;
}

.h-notif {
  background: #a10606;
  padding: 6px;
  text-align: center;
  position: relative
}

.h-notif-text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;
}

.h-notif-close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.h-top {
  background: #fff;
}

.header-text-w {
  color: #ffffff;
  margin-left: 20px;
  font-size: 12px !important;
  cursor: pointer;
}

.header-text-b {
  color: #000000;
  margin-left: 20px;
  font-size: 12px !important;
  cursor: pointer;
}

.search-text-w {
  /* color: #FFFFFF; */
  margin-right: 10px;
  font-size: 22px !important;
  font-weight: 800;
}

.search-text {
  /* color: #000000; */
  margin-right: 10px;
  font-size: 22px !important;
  font-weight: 800;
}

.page-title {
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  color: #000000;
  font-size: 0.8rem !important;
  font-weight: 700;
}

.mr-100 {
  margin-right: 300px;
}

.line-w {
  width: 150px;
  border: 1px solid #ffffff;
}

.line-b {
  width: 150px;
  border: 1px solid #000000;
}

.text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.inline {
  display: inline-block;
}

.img-logo {
  width: 100%;
  max-width: 120px;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.divider {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.img-home {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 20;
}

.img-home-logo {
  width: 100%;
  max-width: 100px;
}

.selected-tab {
  background-color: #610303;
}
</style>
