import Vue from 'vue'
import Vuex from 'vuex'
import crypto from '@/crypto'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headerColor: 'black',
    cart: [],
    cartNumber: 0,
    userName: '',
    userEmail: '',
    text: '',
    token: '',
    snackbar: '',
    isLoading: false,
    isMobile: false,
    isSearch: false,
    isHome: true,
    isLoginPage: false,
    isLogin: false,
    isMemberPage: false,
    activePage: '',
    category: '',
    productType: '',
    menuItems: [],
    isSideBarOpen: false,
    shipping: null,
    delivery: null,
    voucher: null,
    canBack: false,
    yIndex: '0-0',
    yIndexHome: null,
    loginDialog: false,
    counterLoading: 0,
    footer: null,
    notifNumber: 0,
    reviewNumber: 0,
    personalData: {},
    activeTab: null,
    shippingOptions: null,
    shopOffset: 0,
    shopData: [],
    shopIsChild: "NO",
    latestShopData: [],
    shopRoute: '',
    isCampaign: null,
  },
  getters: {
    getIsLoading: state => { return state.isLoading },
    getIsMobile: state => { return state.isMobile },
    getIsSearch: state => { return state.isSearch },
    getIsHome: state => { return state.isHome },
    getIsLoginPage: state => { return state.isLoginPage },
    getIsLogin: state => { return state.isLogin },
    getIsMemberPage: state => { return state.isMemberPage },
    getActivePage: state => { return state.activePage },
    getLoginDialog: state => { return state.loginDialog },
    getFooter: state => { return state.footer },
    getActiveTab: state => { return state.activeTab },
    getShippingOptions: state => { return state.shippingOptions }
  },
  mutations: {
    setHeaderColor (state, payload) {
      state.headerColor = payload
    },
    setLoginDialog (state, payload) {
      state.loginDialog = payload
    },
    setYIndex (state, payload) {
      state.yIndex = payload
    },
    setYIndexHome (state, payload) {
      state.yIndexHome = payload
    },
    setCanBack (state, payload) {
      state.canBack = payload
    },
    setVoucher (state, payload) {
      state.voucher = payload
    },
    setIsLoading (state, payload) {
      if (payload) {
        state.counterLoading +=1
      } else {
        state.counterLoading -=1
      }

      if (state.counterLoading === 0) {
        state.isLoading = false
      } else {
        state.isLoading = true
      }
    },
    setIsMobile (state, payload) {
      state.isMobile = payload
    },
    setIsSearch (state, payload) {
      state.isSearch = payload
    },
    setIsHome(state, payload) {
      state.isHome = payload
    },
    setIsLoginPage (state, payload) {
      state.isLoginPage = payload
    },
    setIsLogin(state, payload) {
      state.isLogin = payload
    },
    setIsMemberPage(state, payload) {
      state.isMemberPage = payload
    },
    setActivePage(state, payload) {
      state.activePage = payload
    },
    setSideBarPanel(state, payload) {
      state.isSideBarOpen = payload
    },
    setUserLogin (state, payload) {
      state.userName = payload.name
      state.userEmail = payload.email
      state.token = payload.token
    },
    setSnackbar (state, payload) {
      state.snackbar = payload
      setTimeout(()=>{
        state.snackbar = false
      }, 5000)
    },
    setText (state, payload) {
      state.text = payload
    },
    setShipping (state, payload) {
      state.shipping = payload
    },
    setDelivery(state, payload) {
      state.delivery = payload
    },
    setCart (state, payload) {
      state.cart = payload

      state.cartNumber = 0
      payload.map(item => {
        state.cartNumber += Number(item.quantity)
      })

      localStorage.setItem('prepp_c', crypto.encrypt(state.cart))
    },
    addToCart (state, payload) {
      if (state.cart.length > 0) {
        let data = state.cart.find(val => val.size.sku_product === payload.size.sku_product)
        if (data) {
          data.quantity += 1
        } else {
          state.cart.push(payload)
        }
      } else {
        state.cart.push(payload)
      }
    
      state.cartNumber = 0
      state.cart.map(item => {
        state.cartNumber += Number(item.quantity)
      })

      localStorage.setItem('prepp_c', crypto.encrypt(state.cart))
    },
    addToCartBundling (state, payload) {
      // if (state.cart.length > 0) {
      //   let data = state.cart.find(val => val.isBundling)
      //   if (data) {
      //     data.quantity += 1
      //   } else {
      //     state.cart.push(payload)
      //   }
      // } else {
        state.cart.push(payload)
      // }
    
      state.cartNumber = 0
      state.cart.map(item => {
        state.cartNumber += Number(item.quantity)
      })

      localStorage.setItem('prepp_c', crypto.encrypt(state.cart))
    },
    setCategory (state, payload) {
      state.category = payload
    },
    setProductType (state, payload) {
      state.productType = payload
    },
    setMenuItems (state, payload) {
      state.menuItems = payload
    },
    setFooter (state, payload) {
      state.footer = payload
    },
    setNotifNumber (state, payload) {
      state.notifNumber = payload
    },
    setReviewNumber (state, payload) {
      state.reviewNumber = payload
    },
    setPersonalData (state, payload) {
      state.personalData = payload
    },
    setActiveTab (state, payload) {
      state.activeTab = payload
    },
    setShippingOptions (state, payload) {
      state.shippingOptions = payload
    },
    setShopOffset (state, payload) {
      state.shopOffset = payload
    },
    setShopData (state, payload) {
      state.shopData = payload
    },
    setShopRoute (state, payload) {
      state.shopRoute = payload
    },
    setLatestShopData (state, payload) {
      state.latestShopData = payload
    },
    setShopIsChild (state, payload) {
      state.shopIsChild = payload
    },
    setIsCampaign (state, payload) {
      state.isCampaign = payload
    },
    initialiseStore (state) {
      if (localStorage.getItem('prepp_n')) {
        let name = localStorage.getItem('prepp_n')
        state.userName = crypto.decrypt(name)
        state.isLogin = true
      }
      if (localStorage.getItem('prepp_e')) {
        let email = localStorage.getItem('prepp_e')
        state.userEmail = crypto.decrypt(email)
      }
      if (localStorage.getItem('prepp_t')) {
        let token = localStorage.getItem('prepp_t')
        state.token = crypto.decrypt(token)
      }
      if (localStorage.getItem('prepp_c')) {
        let cart = localStorage.getItem('prepp_c')
        state.cart = crypto.decrypt(cart)

        state.cartNumber = 0
        if (cart.length > 0) {
          state.cart.map(item => {
            state.cartNumber += Number(item.quantity)
          })
        }
      }

      if (localStorage.getItem('is_campaign')) {
        let isCampaign = localStorage.getItem('is_campaign')
        state.isCampaign = crypto.decrypt(isCampaign)
      }
    }
  },
  actions: {}
})
